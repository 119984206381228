<template>
  <div class="bg-white p-1">
    <form-render
      v-show="!loading.main"
      class="mb-2"
      :fields.sync="fields"
      :form.sync="form"
      :key="formRenderServices"
      ref="formRenderServices"
      containerButtonsClass="col-sm-12 col-lg-4 container-button"
      @send="onAccept"
      @invalidForm="showAlert"
    >
      <!-- <template #timeCourt>
        <label for="timepicker-courthours">{{labelTimerPicker}}</label>
        <b-form-timepicker
          id="datepicker-courthours"
          locale="en"
          :state= "courthoursActive"
          @input="courtHoursChange"
          minutes-step="5"
          label-selected="Hijo eh p[puta"
          v-model="court_hour"
        ></b-form-timepicker>
      </template> -->
    </form-render>
    <div v-show="loading.main">
      <b-skeleton width="30%"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'service-data-form',
  props: ['formTabData', 'courthoursActiveFn', 'labelTimerPick', 'saveData', 'tabActive'],
  data() {
    return {
      form: {},
      fields: [],
      loading: { main : false },
      courthoursActive: true,
      court_hour: null,
      labelTimerPicker: 'Corte de horario',
      sharedOptions : [{ id: 1, text: this.$t('No') }, { id: 2, text: this.$t('Sí') }],
      optionsDimensions: [
        {
          id: 20,
          html: `
            <img src="/icon/sobre.svg" />
            <span>Sobre</span>
          `,
          code: 'envelope',
          text: 'Sobre',
          icon: 'MailIcon',
          length: 20,
          height: 30,
          width: 0.2
        },
        {
          id: 1,
          html: `
            <img src="/icon/small.svg" />
            <span>Pequeño</span>
          `,
          text: 'Pequeño (Ej. celular)',
          code: 'small',
          length: 25,
          height: 15,
          width: 8
        },
        {
          id: 221,
          html: `
            <img src="/icon/medium.svg" />
            <span>Mediano</span>
          `,
          text: 'Mediano (Ej. celular)',
          code: 'medium', length: 25,
          height: 15,
          width: 8
        },
        {
          id: 2,
          html: `
            <img src="/icon/grande.svg" />
            <span>Grande</span>
          `,
          text: 'Grande (Ej. microondas)',
          code: 'large',
          length: 60,
          height: 40,
          width: 30
        },
        {
          id: 3,
          html: `
            <img src="/icon/big.svg" />
            <span>Mas Grande</span>
          `,
          text: 'Mas Grande (Ej. lavadora)',
          code: 'xlarge',
          length: 100,
          height: 100,
          width: 120
        }
      ],
      hours24: Array.from({ length: 24 }, (num, i) => ({ id: `${i + 1}:00`, text: `${i + 1}:00` })),
      formRenderServices: 0
    }
  },
  computed: {
    ...mapGetters({
      carrier: 'getCarrier',
      serviceType: 'getServiceType',
      clasification: 'getClasificationServices',
      serviceCarriers: 'getServiceCarriers'
    }),
    editing() {
      return !!this.formTabData?.id
    }
  },
  watch: {
    labelTimerPick() {
      this.labelTimerPicker = this.labelTimerPick
    },
    formTabData() {
      this.setData()
    },
    editing() {
      if (!this.editing) {
        this.form = {}
      }
    },
    clasification () {
      if (this.editing) {
        this.form.service_classification = this.clasification.map(value => ({...value, text : value.name})).find(value => value.id === this.formTabData.service_classification_id) ?? null
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-md-6 container-info col-sm-12', validation: 'required' },
        { fieldType: 'FieldInput', name: 'code', label: 'Código', containerClass: 'col-md-6 container-info col-sm-12', validation: 'required' },
        { fieldType: 'FieldTags', name: 'alias', label: 'Nombre de Alias', clearable: true, color:'primary', containerClass: 'col-md-12 col-sm-12 container-info', placeholder: 'Ingrese nombre de alias', description: 'Ingrese hasta diez nombre de alias que desee, separados por una coma o un enter.',
          tagsOption: {
            limitTagsText: this.$t('Limite de alias alcanzadas'),
            duplicateTagText: this.$t('Alias duplicado'),
            limit: 10
          }
        },
        { fieldType: 'FieldSelect', name: 'service_classification', label: 'Categoria de servicio', containerClass: 'col-sm-12 col-md-12 container-info pb-1', validation: 'required' },
        { fieldType: 'FieldRadio', name: 'is_normal', label: 'Servicio forward', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', align: 'h', options: this.sharedOptions, change: (name, value) => this.handleRadioChanges(name, value, 'is_default') },
        { fieldType: 'FieldRadio', name: 'is_return', label: 'Servicios Devoluciones', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', align: 'h', options: this.sharedOptions, change: (name, value) => this.handleRadioChanges(name, value, 'is_default_return') },
        {
          fieldType: 'FieldCheckbox', name: 'is_default', label: '', containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', disabled: true,
          options: [{ id: 1, text: 'Servicio forward por defecto' }]
        },
        {
          fieldType: 'FieldCheckbox', name: 'is_default_return', label: '', containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', disabled: true,
          options: [{ id: 1, text: 'Servicios devoluciones por defecto' }]
        },
        { fieldType: 'FieldSchedule', name: 'scheduling', rangeLabelStart: 'Horario de Entrega', daysLabel: 'Días de Entrega', containerClass: 'container-info col-sm-12 col-md-12 pt-1', hoursOptions: { open: this.hours24, close: this.hours24 } },
        { fieldType: 'FieldSpinbutton', name: 'delivery_attempts', label: 'Intentos de Entrega', specsRange: { min: 0 }, classField: 'w-70', containerClass: 'container-info col-sm-12 col-md-5' },
        { fieldType: 'FieldRadio', name: 'has_package', label: 'Multibulto', options: this.sharedOptions, change: (name, value) => this.handleRadioChanges(name, value, 'max_packages'), containerClass: 'col-sm-4 col-md-2 container-info', align: 'h' },
        { fieldType: 'FieldInput', name: 'max_packages', label: 'Máximo de bultos', type: 'number', disabled: true, containerClass: 'col-sm-8 col-md-5 container-info' }
      ]
      this.setData()
    },
    setData() {
      if (this.editing) {
        const hasMaxP = this.formTabData.min_packages > 1
        this.form = {
          alias: this.formTabData?.alias ?? [],
          name: this.formTabData?.name ?? null,
          code: this.formTabData?.code ?? null,
          service_classification: this.clasification.map(value => ({...value, text : value.name})).find(value => value.id === this.formTabData.service_classification_id) ?? null,
          is_normal: this.formTabData?.is_normal ? this.sharedOptions[1] : this.sharedOptions[0],
          is_return: this.formTabData?.is_return ? this.sharedOptions[1] : this.sharedOptions[0],
          is_default: this.formTabData?.is_default ? { id: 1, text: 'Servicio forward por defecto' } : false,
          is_default_return: this.formTabData?.is_default_return ? { id: 1, text: 'Servicios devoluciones por defecto' } : false,
          scheduling: !this.formTabData?.scheduling || this.formTabData?.scheduling?.every(item => item.days.length === 0) ? null : this.formTabData.scheduling.map((item) => ({
            open: { id: item?.open, text: item?.open },
            close: {
              id: item?.close?.id?.trim() ?? item?.close?.trim(),
              text: item?.close?.id?.trim() ?? item?.close?.trim()
            },
            days: item?.days?.map((day) => ({
              id: day.substring(2, 0).toUpperCase(),
              text: day,
              disabled: false
            }))
          })),
          delivery_attempts: Number(this.formTabData?.delivery_attempts) ?? null,
          max_packages: this.formTabData?.max_packages ?? null,
          has_package: hasMaxP ? { id: 2, text: this.$t('Sí') } : false
        }
        if (hasMaxP) this.fields[this.fields.findIndex(({name}) => name === 'max_packages')].disabled = false
      }

      const serviceNameIndex = this.fields.findIndex(el => el.name === 'service_classification')
      this.fields[serviceNameIndex].options = this.clasification?.map(value => ({
        ...value,
        text: value.name
      }))
    },
    getFormService() {
      const form = {
        carrier_id: parseInt(this.$route.params.id),
        name: this.form?.name,
        code: this.form?.code,
        alias: this.form?.alias ?? [],
        service_classification_id: this.form?.service_classification?.id,
        is_normal: this.form?.is_normal?.id !== 1,
        is_return: this.form?.is_return?.id !== 1,
        is_default: this.form?.is_default?.id === 1,
        is_default_return: this.form?.is_default_return?.id === 1,
        scheduling: this.form?.scheduling?.map((day) => ({ 
          days: this.getDays(day),
          open: day?.open?.id,
          close: day?.close?.id
        })) ?? [],        
        delivery_attempts: this.form.delivery_attempts.toString() || null
      }
      if (!this.form.has_package) {
        form.min_packages  = 1
        form.max_packages = 1
      } else {
        form.min_packages = Number(this.form.max_packages)
        form.max_packages = Number(this.form.max_packages)
      }
      return form
    },
    getDays(data) {
      const findAllDays = data?.days?.findIndex((day) => day?.id === '*')
      if (findAllDays !== -1) return data?.days[findAllDays]?.days
      return data?.days?.map((day) => day.text)
    },
    onAccept(data) {
      this.loading.callingService = true
    },
    onlyNumber(name, value) {
      this.form.additional_insurance = value.replace(/\D/g, '')
    },
    async ok() {
      return await this.$refs.formRenderServices.onlyCheckForm()
    },
    courtHoursChange(value, name) {
      const hourValid = value.split(':')[0]
      if (parseInt(hourValid) > 6 && parseInt(hourValid) < 24) {
        this.courthoursActive = true
        this.$emit('courthoursActiveFn', true)
        this.labelTimerPicker = 'Corte de horario'
      } else {
        this.courthoursActive = false
        this.$emit('courthoursActiveFn', false)
        this.labelTimerPicker = this.$t('msg-horario-invalido')
      }
    },
    showAlert() {
      if (this.tabActive === 1) {
        this.$bvToast.toast('Por favor verifique la pestaña de datos de servicios', {
          title: 'Campos requeridos vacios',
          variant: 'danger',
          solid: true
        })
      }
    },
    handleRadioChanges(_, value, target) {
      const index = this.fields?.findIndex(el => el.name === target)
      const isFalse = (value?.id === 1)
      this.fields[index].disabled = isFalse
      if (isFalse) {
        this.form[`${target}`] = target === 'max_packages' ? null : false
      }
      this.formRenderServices++
    }
  }
}
</script>
<style>
.option-dimension{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
}
.bg-white{
  background-color: white;
}
</style>