<template>
  <div class="bg-white p-1">
    <form-render
      class="mb-2"
      :fields.sync="fields"
      :form.sync="form"
      :key="formRenderSpecialConditions"
      ref="formRenderSpecialConditions"
      containerButtonsClass="col-sm-12 col-lg-4 container-button"
    >
      <template #court_hours>
        <div class="d-flex w-100" >
          <b-form-input
            ref="court_hours"
            placeholder="Ingrese horario de corte"
            type="text"
            :min="0"
            :max="100"
            name="court_hours"
            v-model="form.court_hours"
            aria-describedby="input-live-help"
            class="inputApplyDiscountOrIncrease"
            required
            :key="keyIncreaseOrDiscount"
            :class="!hasData.time ? 'is-invalid' : ''"
            @change="val => courtHourChange(val, 'time')"
          ></b-form-input>
            <v-select
              id="increaseOrDiscount"
              v-model="form.increaseOrDiscount"
              :options="courtHoursOptions"
              :clearable="false"
              class="inputIncreaseOrDiscount"
              placeholder="Seleccionar"
              label="text"
              :class="!hasData.option ? 'is-invalid' : ''"
              @input="val => courtHourChange(val, 'option')"
            >
              <template #option="{ name }">
                <span>{{name}}</span>
              </template>
              <template #selected-option="{ name }">
                <div style="display: flex; align-items: baseline">
                  {{ name }}
                </div>
              </template>
            </v-select>
        </div>
      </template>
    </form-render>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'


export default {
  name: 'special-conditions-form',
  components: { vSelect },
  props: ['formTab', 'loading', 'courthoursActiveFn'],
  data() {
    return {
      form: {},
      fields: [],
      sharedOptions : [{ id: 1, text: 'No' }, { id: 2, text: 'Sí' }],
      formRenderSpecialConditions: 0,
      hours24: Array.from({ length: 24 }, (num, i) => ({ id: `${i + 1}:00`, text: `${i + 1}:00` })),
      courtHoursOptions: [],
      baseService: new BaseServices(this),
      keyIncreaseOrDiscount: 0,
      hasData: {
        time: true,
        option: true
      },
      firstCheck: false,
      manifestOpt: [{ id: 1, text: 'Servicio requiere Manifiestos' }],
      carrierLabelOpt: [{ id: 1, text: this.$t('No') }, { id: 2, text: this.$t('Si') }]
    }
  },
  computed: {
    ...mapGetters({
      pickupType: 'getPickupType',
      schedulingType: 'getSchedulingType'
    }),
    editing() {
      return !!this.formTab?.id
    }
  },
  watch: {
    formTab() {
      this.setData()
    },
    editing() {
      if (!this.editing) {
        this.form = {}
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.getCourtHours()
      this.fields = [
        { fieldType: 'FieldSelect', name: 'pickup_point', label: 'Punto de retiro', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'scheduling_type', label: 'Tipo de Agendamiento', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldRadio', name: 'own_carrier_label', label: 'Cliente imprime etiqueta del operador', options: this.carrierLabelOpt, containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', change: (n, v) => this.handleRadioChanges(n, v, 'manifest_required') },
        { fieldType: 'FieldCheckbox', name: 'manifest_required', options: this.manifestOpt, containerClass: 'col-sm-12 col-md-6 container-info', align: 'h' },
        { fieldType: 'FieldInput', name: 'min_pickups', label: 'Mínimo de Retiro', type: 'number', containerClass: 'col-sm-12 col-md-6 container-info' },
        { name: 'court_hours', label: 'Horario de Corte', containerClass: 'col-sm-12 col-md-6 container-info', useSlot: true, aditionalLabel: {type: 'danger', text: '*'} },
        { fieldType: 'FieldCheckbox', name: 'has_min_pickups', label: '', options: [{ id: 1, text: 'Sin mínimo' }], change: (n, v) => this.handleRadioChanges(n, v, 'min_pickups'), containerClass: 'col-12 container-info', align: 'h' },
        { fieldType: 'FieldSchedule', name: 'pickup_schedule', rangeLabelStart: 'Horario de retiro', daysLabel: 'Días de retiro', hoursOptions: { open: this.hours24, close: this.hours24 }, containerClass: 'col-sm-12 col-md-12 container-info pt-1' },
        { fieldType: 'FieldTextarea', name: 'pickup_summary', label: 'Otras consideraciones de retiros', placeholder: 'Ingrese resumen', containerClass: 'col-sm-12 container-info'}
      ]
      this.setData()
    },
    async ok() {
      if (!this.firstCheck) this.firstCheck = true
      const form = await this.$refs.formRenderSpecialConditions.onlyCheckForm()
      this.hasData = {
        option: !!this.form.increaseOrDiscount?.id,
        time: !!this.form.court_hours
      }
      const isValid = form && this.hasData.time && this.hasData.option

      return isValid
    },
    getCourtHours() {
      this.baseService.callService('getCourtHourType')
        .then(res => {
          this.courtHoursOptions = res.data.options.map((el) => ({...el, text: el.name}))
          if (this.editing) {
            this.form.increaseOrDiscount =  this.courtHoursOptions.find(val => val.id === this.formTab.court_hours_id)
          }
          this.keyIncreaseOrDiscount++
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    courtHourChange(data, from) {
      if (!this.firstCheck) return
      this.hasData[from] = !!data
    },
    setData() {
      if (this.editing) {
        this.form = {
          pickup_point: this.formTab?.pickup_point,
          scheduling_type: this.formTab?.scheduling_type,
          own_carrier_label: this.formTab.own_carrier_label ? { id: 2, text: 'Sí' } : { id: 1, text: 'No' },
          manifest_required: this.formTab.manifest_required ? { id: 1, text: 'Servicio requiere Manifiestos' } : null,
          min_pickups: this.formTab.min_pickups || null,        
          court_hours: this.formTab.court_hours,
          pickup_schedule: !this.formTab?.pickup_schedule || this.formTab?.pickup_schedule?.every(item => item.days.length === 0) ? null : this.formTab.pickup_schedule.map((item) => ({
            open: { id: item?.open, text: item?.open },
            close: {
              id: item?.close?.id?.trim() ?? item?.close?.trim(),
              text: item?.close?.id?.trim() ?? item?.close?.trim()
            },
            days: item?.days?.map((day) => ({
              id: day.substring(2, 0).toUpperCase(),
              text: day,
              disabled: false
            }))
          })),
          pickup_summary: this.formTab.pickup_summary || null
        }
        this.formRenderSpecialConditions++
      }

      const pickupTypeIndex = this.fields.findIndex(el => el.name === 'pickup_point')
      this.fields[pickupTypeIndex].options = this.pickupType

      const schedulingTypeIndex = this.fields.findIndex(el => el.name === 'scheduling_type')
      this.fields[schedulingTypeIndex].options = this.schedulingType
    },
    getFormSpecial() {
      const form = {
        pickup_point: this.form?.pickup_point?.text ?? this.form?.pickup_point ?? null,
        scheduling_type: this.form?.scheduling_type?.text ?? this.form?.scheduling_type ?? null,
        own_carrier_label: this.form?.own_carrier_label?.id !== 1,
        court_hours: this.form?.court_hours,
        court_hours_id: this.form.increaseOrDiscount?.id,
        pickup_schedule: this.form?.pickup_schedule?.map((day) => ({ 
          days: this.getDays(day),
          open: day?.open?.id,
          close: day?.close?.id
        })) ?? [],
        manifest_required: !!this.form.manifest_required,
        pickup_summary: this.form.pickup_summary || ''
      }
      if (this.form?.min_pickups) form.min_pickups = this.form?.min_pickups
      return form
    },
    getDays(data) {
      const findAllDays = data?.days?.findIndex((day) => day?.id === '*')
      if (findAllDays !== -1) return data?.days[findAllDays]?.days
      return data?.days?.map((day) => day.text)
    },
    handleRadioChanges(name, value, target) {
      const index = this.fields.findIndex(el => el.name === target)
      const isFalse = name === 'has_min_pickups' ? value : (value?.id === 1)
      this.fields[index].disabled = isFalse
      if (isFalse && this.form[target]) this.form[target] = target === 'manifest_required' ? false : null
    },
    sendFormData() {
      const clearData = {
        ...this.form,
        payment_by_return: this.form.payment_by_return?.id !== 1,
        reexpeditions: this.form.reexpeditions?.id !== 1,
        failed_pickups: this.form.failed_pickups?.id !== 1,
        other_charges: this.form.other_charges?.id !== 1,
        delivery_attempts: this.form.delivery_attempts.toString() || null,
        summary: this.form.summary || ''
      }
      delete clearData.has_package
      delete clearData.has_min_pickups

      if (!this.form.has_package) {
        clearData.min_packages  = 1
        clearData.max_packages = 1
      } else {
        clearData.min_packages = Number(clearData.max_packages)
        clearData.max_packages = Number(clearData.max_packages)
      }
      return clearData
    },
    validateHourFormat (name, value) {
      if (!value) {
        this.$emit('courthoursActiveFn', true)
        return
      }
      const index = this.fields.findIndex(el => el.name === 'court_hours')
      const hours = value.replace(/\D/g, '').substring(0, 6)
      const clearHours = (Number(hours.substring(0, 2)) > 24 ? '24' : hours.substring(0, 2)) + (Number(hours.substring(2, 4)) > 59 ? '59' : hours.substring(2, 4)) + (Number(hours.substring(4, 6)) > 59 ? '59' : hours.substring(4, 6))
      this.form.court_hours = (hours.length === 6) ? clearHours.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3') : clearHours.replace(/(\d{2})(\d{2})/, '$1:$2')

      if (hours.length < 4) {
        this.$bvToast.toast('Por favor verifique el horario de corte', {
          title: 'Formato incorrecto',
          variant: 'danger',
          solid: true
        })
        this.$emit('courthoursActiveFn', false)
        return
      }
      this.$emit('courthoursActiveFn', true)
    }
  }
}
</script>
<style lang="scss">
.option-dimension{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
}
.bg-white{
  background-color: white;
}
.inputIncreaseOrDiscount {
  width: 40%;
  > div {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
    border-left: none !important;
    min-height: 100% !important;
  }
  > ul {
    top: auto;
    min-width: 100px;
  }
}
.inputApplyDiscountOrIncrease {
  width: 60%;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}
</style>
